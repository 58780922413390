import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9b315ee6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["accept", "id"]
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("input", {
      accept: _ctx.accept,
      type: "file",
      id: _ctx.randId,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onchange && _ctx.onchange(...args)))
    }, null, 40, _hoisted_1),
    _createElementVNode("label", { for: _ctx.randId }, [
      _renderSlot(_ctx.$slots, "default", {
        file: _ctx.file,
        imageURL: _ctx.imageURL
      }, undefined, true)
    ], 8, _hoisted_2)
  ], 64))
}