import { createInstance } from "@/lib/fn";
import { NewsPaginate } from "@/models/news";
import BaseResourceHttp from "@/lib/resourceHttp";

class News extends BaseResourceHttp<NewsPaginate, any, any> {
    protected baseURL(): string {
        return "news";
    }
}

export default createInstance<News>(News);