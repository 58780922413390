
import { helperMixin, saveAndUpdate } from "@/mixins";
import { NewsDetail, NewsSave } from "@/models/news";
import { defineComponent } from "vue";
import newsHttp from "@/http/news";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseFormApp from "@/components/Base/BaseFormApp.vue";
import TeleportToContentFooter from "@/components/TeleportToContentFooter.vue";
import BaseFileInput from "@/components/Base/BaseFileInput.vue";

export default defineComponent({
  components: {
    TeleportToContentFooter,
    BaseFormGroup,
    BaseFormApp,
    BaseFileInput,
  },
  mixins: [helperMixin, saveAndUpdate<NewsSave, NewsDetail>(newsHttp())],
  data() {
      return {
          currentImageLink: ""
      }
  },
  methods: {
    setForm() {
      this.form.title = this.data?.title;
      this.form.body = this.data?.body;
      this.form.image = null;
      this.currentImageLink = this.data?.image_link;
    }
  }
});
