
import { randomString } from "@/helpers";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
    },
    toBase64: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "updated"],
  data() {
    return {
      inputValue: "" as any,
      imageURL: "",
      file: null as any,
    };
  },
  computed: {
    randId() {
      return randomString();
    },
  },
  methods: {
    getImageURL(file: File) {
        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => {
          this.imageURL = reader.result as string;
        };
    },

    base64encode(file: File) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => {
          this.imageURL = reader.result as string;
          resolve(
            (reader.result as string).replace("data:", "").replace(/^.+,/, "")
          );
        };

        reader.onerror = reject;
      });
    },
    async onchange(e: any) {
      const files = e.target.files || e.dataTransfer.files;

      if (this.multiple) {
        this.inputValue = [];
        this.file = [];

        for (let i = 0; i < files.length; i++) {
          this.file.push(files[0]);
          await new Promise(async (resolve) => {
            this.inputValue.push(
              this.toBase64 ? await this.base64encode(files[i]) : files[i]
            );

            resolve;
          });
        }
      } else {
        this.file = files[0];

        this.getImageURL(this.file);

        this.inputValue = this.toBase64
          ? await this.base64encode(files[0])
          : files[0];
      }

      this.$emit("update:modelValue", this.inputValue);
    },
  },
});
