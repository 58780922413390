import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center flex-wrap" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "mt-3 text-primary w-full text-center font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_file_input = _resolveComponent("base-file-input")!
  const _component_base_form_group = _resolveComponent("base-form-group")!
  const _component_base_form_app = _resolveComponent("base-form-app")!
  const _component_teleport_to_content_footer = _resolveComponent("teleport-to-content-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_form_app, {
      onSubmit: _ctx.submit,
      loading: _ctx.loading,
      "onUpdate:loading": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.loading) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_base_form_group, {
          class: "my-3 border-dashed border-4 mx-auto",
          "input-type": "custom",
          "invalid-message": _ctx.validationErrors.image
        }, {
          default: _withCtx(() => [
            _createVNode(_component_base_file_input, {
              modelValue: _ctx.form.image,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.image) = $event)),
              accept: ".png,.jpg,.jpeg"
            }, {
              default: _withCtx(({ imageURL }) => [
                _createElementVNode("div", _hoisted_1, [
                  imageURL
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        class: "news-image",
                        src: imageURL
                      }, null, 8, _hoisted_2))
                    : (_ctx.currentImageLink)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 1,
                          class: "news-image",
                          src: _ctx.currentImageLink
                        }, null, 8, _hoisted_3))
                      : _createCommentVNode("", true),
                  _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.currentImageLink ? _ctx.trans("layout.news.change_image") : _ctx.trans("layout.news.upload_image")), 1)
                ])
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["invalid-message"]),
        _createVNode(_component_base_form_group, {
          label: _ctx.trans('layout.news.title'),
          modelValue: _ctx.form.title,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.title) = $event)),
          "invalid-message": _ctx.validationErrors.title
        }, null, 8, ["label", "modelValue", "invalid-message"]),
        _createVNode(_component_base_form_group, {
          label: _ctx.trans('layout.news.body'),
          modelValue: _ctx.form.body,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.body) = $event)),
          "invalid-message": _ctx.validationErrors.body,
          "input-type": "textarea"
        }, null, 8, ["label", "modelValue", "invalid-message"])
      ]),
      _: 1
    }, 8, ["onSubmit", "loading"]),
    _createVNode(_component_teleport_to_content_footer)
  ], 64))
}